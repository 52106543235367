import React,{ Component } from "react";
import { Link } from 'react-router-dom';
//import NumericInput from 'react-numeric-input';
import InfiniteScroll from "react-infinite-scroll-component";

import Button from 'react-bootstrap/Button';
//import Container from 'react-bootstrap/Container';

  
const scanned_items = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"green",
  color:"#FFFFFF"
};

const scanned_pending = {
  height: 40,
  border: "1px solid green",
  margin: 3,
  padding: 3,
  background:"#FFA500",
  color:"#FFFFFF"
};

const small_font = {
  margin: 0,
  padding: 0,
  fontSize:10
};





class Docdetails extends Component {

  state = {
    items: [],
    Fixeditems: [],
    hasQty: false,
    upScanQty: false,
    spinner:false,
    totalOrderQty: 0,
    qtyButton: JSON.parse(sessionStorage.getItem('currentLang'))[66],
    fullScrenButton: 'Full Screen',
    handlefindItem: this.findItem.bind(this, 'DocumentItemFind'),
    //handlefindItemInput:this.findItem.bind(this, 'DocumentItemFindInput'),
    docItem: [],
    total_scanned_item:0,
    total_released_item:0,
    scanned_ean_code:null,
    hasFullScrn: false,
    visibility: "block",
    showHideEnale: false,
    layoutName: "default",
    input: "",
    input3:"",
    qtyInput:1,
    keyboardVisibility:false,
    keyboardVisibilityQty:false,
    keyboardVisibilityScanQty:false,
    itemSelectedId:0,
    itemSelectedPrevQty:0,
    itemSelected:false,
    itemSelectedAvailQty:0,
    itemSelectedBgColor:0,
    incnum: 0,
    //onNewScanResult: this.onNewScanResult.bind(this, 'ScannedItemFind'),
    webcamResult:"Not Found",
    setStopStream:false,
    sendToSap:false,
    AllComments:'',
  };

  findDocument(loadStatus) {
   
    //if (event.charCode === 13) { for enter key
    const docId = this.props.match.params.id;
    const currentUser = localStorage.getItem('username');
    this.setState({ spinner: true });
    this.setState({ upScanQty: false });
    if(docId.length > 0){
              fetch('/api/v1/documents/docitems/'+docId+'/'+currentUser+'/'+loadStatus, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  console.log( data)
                  //document.getElementById("item_ean").value='';
                  this.setState({itemSelected: false}); 
                  const Binrows = [];
                  for (let i = 0; i < data.docItemList.length; i++) {
                      // note: we are adding a key prop here to allow react to uniquely identify each
                      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                      Binrows.push(data.docItemList[i]);
                  }

                  this.setState({ spinner: false });
                  //alert(data.total_released_item);
                  const focusField = document.querySelector(
                    `input[name=item_ean]`
                  );
                    focusField.focus();
                this.setState({ docItem: data.document, items: Binrows,Fixeditems:Binrows,total_released_item:data.total_released_item, total_scanned_item: data.total_scanned_item, AllComments:data.AllComments });
                //if(loadStatus === 0){
                //this.setState({ showHideEnale: true });
                //}
                // this.setState({ items: data.docItemList });
                //console.log( this.state.spinner);
                //console.log( data.document)
                
                //this.setState({ todoList: data })

                

                if(Binrows.length <=0){
                  alert('Item are already Picked or there is no released QTY for the sales order item(s)!');
                  window.location.replace('/pickandpack');
                }
                
                
                
              }
              ).catch(error => {
                console.log( error);
                //document.getElementById("item_ean").value='';
                this.setState({itemSelected: false}); 
                this.setState({ spinner: false });

                 
                  //this.setState({ docItem: [], items: [],Fixeditems:[],total_released_item:0, total_scanned_item: 0, AllComments:"" });
                   alert('Item are already Picked or there is no released QTY for the sales order item(s)!');
                  window.location.replace('/pickandpack');
              });  
    
      } //if
    
    };

    
    readCommentConfirm = () => {
      const docId = this.props.match.params.id;
      const currentUser = localStorage.getItem('username');
      const postData = {
        docId: docId,
        userName: currentUser,
      };
      if(currentUser){
                    fetch('/api/v1/documents/readcomentConfirm', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`
                      },
                      body: JSON.stringify(postData)
                    })
                    .then(res => res.json())
                    .then(data => {
                      console.log( data);
                     // this.findDocument();    
                     window.location.reload();                         
            
                              });     
                  }                
          };   
 
          tapToHideShow = () => {
            if(this.state.showHideEnale === false){
            this.setState({ showHideEnale: true });
            }
            else{
              this.setState({ showHideEnale: false });
            }
          };  
  sendToSap = () => {    
    if(parseInt(this.state.total_released_item) === parseInt(this.state.total_scanned_item)){

      const docId = this.props.match.params.id;
      const Uid = localStorage.getItem('userId');
      const CardNumber = localStorage.getItem('card_number');
      const isItemScanned =this.state.total_scanned_item.length;
      //alert(Uid);
      if(parseInt(isItemScanned) <=0){
        alert("Please scan the item and try again!");
        return false;
      }
      this.setState({sendToSap:true});
      fetch('/api/v1/documents/sapSyncConfirm/'+Uid+'/'+docId+'/'+CardNumber, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
      .then(res => res.json())
      .then(data => {
        this.setState({sendToSap:false});
        //alert(data.res.SyncResponseDocNoteCode);
        console.log( data);
        console.log( data.res.SyncResponse);
        //this.findDocument();                             
                 /* if(parseInt(data.res.SyncResponse) !== 204){
                    alert( "There is an error during pick list submission!");
                  }
                  else*/
                  /* if(parseInt(data.res.SyncResponse) === 204 && parseInt(data.res.SyncResponseDocNoteCode) !== 201 && parseInt(data.res.SyncResponseDocNoteText.DocNUm) ===0){
                   alert( "Error during the delivery note creation:"+data.res.SyncResponseDocNoteText);

                  }
                  else if(parseInt(data.res.SyncResponse) === 204 && parseInt(data.res.SyncResponseDocNoteCode) === 201){
                    //alert( "Sent to SAP and Delivery Note Successfully Generated: DocNumber:"+data.res.SyncResponseDocNoteText.DocNUm);
                    alert( "Sent to SAP and Delivery Note Successfully Generated");
                  }
                  else if(parseInt(data.res.SyncResponseDocNoteText.DocNUm)>0){
                    alert( "Sent to SAP and Delivery Note Successfully Generated: DocNumber:"+data.res.SyncResponseDocNoteText.DocNUm);
                  }
                  else{
                    alert( "Error during the delivery note creation:"+data.res.SyncResponseDocNoteText);
                  }*/

                  /*if(parseInt(data.res.SyncResponse) === 204 && parseInt(data.res.SyncResponseDocNoteCode) !== 201 && parseInt(data.res.SyncResponseDocNoteText.DocNUm) ===0){
                    alert( "Error during the delivery note creation:"+data.res.SyncResponseDocNoteText);
 
                   }

                  else if(parseInt(data.res.SyncResponseDocNoteCode) === 201){
                    alert( "Sent to SAP and Delivery Note Successfully.");

                  }
                  else{
                    alert( "Error during the delivery note creation:"+data.res.SyncResponseDocNoteText);
                  }*/
                  if(parseInt(data.res.SyncResponse) === 204 && parseInt(data.res.SyncResponseDocNoteCode) !== 201 && parseInt(data.res.SyncResponseDocNoteText.DocNUm) ===0){
                    alert( "Sent to SAP, but delivery note was not created."+data.res.SyncResponseDocNoteText);
 
                   }

                  else if(parseInt(data.res.SyncResponseDocNoteCode) === 201){
                    window.location.replace('/pickandpack');
                    //alert( "Sent to SAP and Delivery Note Successfully Created");

                  }
                  else{
                    alert( "Error during the delivery note creation:"+data.res.SyncResponseDocNoteText);
                  }

                  window.location.replace('/pickandpack');
                })
          .catch(error => {
            this.setState({sendToSap:false});
            //console.log( jsonbody);
            //alert("There is an error during the submission, Please go to the Failed TAB and try again!");
            console.log( JSON.parse(sessionStorage.getItem('currentLang'))[67]);
           //window.location.replace('/pickandpack');
        });          

    }
    else{

      alert(JSON.parse(sessionStorage.getItem('currentLang'))[21]);

    }
    
  };





  handleSearch = event => {

    //console.log( event.target.value);
  
    
    //console.log( users);*/
    //console.log( this.state.selectedBinLocQty);
    //console.log( this.state.items);
    let serachTerm = event.target.value.toUpperCase();
  if(serachTerm.length >= 10){
    this.highlightItem(serachTerm);
  }
  
  }; 
  
  highlightItem(serachTerm) {
    
    let allItems = this.state.Fixeditems;
  
    if (serachTerm === "") { this.setState({ items: this.state.Fixeditems }); return ; }


  
    //console.log( allItems);
  var filterBySearch = allItems.filter((item) => {
  
    if (item.Barcode !== null && String(item.Barcode).length >0){
      return item.Barcode.toLowerCase().indexOf(serachTerm.toLowerCase()) > -1;
    } 
      //return filterTitle && filterDocNum && filterPolNumber && filterPolskyOpis;
    });   
    console.log( filterBySearch.length);
    
      //console.log( filterBySearch.i.id);
    //document.getElementById('itemTap'+filterBySearch[0]['id']).removeAttribute("style");  
    //document.getElementById('itemTap'+filterBySearch[0]['id']).setAttribute("style", "height: 60px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
   
  
  console.log( filterBySearch);
  this.setState({ items: filterBySearch });
    
  }; 

    
    findItem(refName, e) {
   
      //if (event.charCode === 13) { for enter key
      let eanNum = e.target.value;
      
      e.preventDefault();
      //loader load
      
      //if(eanNum.length >= 13){
        if(eanNum.length >= 10){
          

     console.log(eanNum);
          if(this.state.scanned_ean_code === null){
            this.setState({ upScanQty: true });
            //this.setState({ scanned_ean_code: eanNum });
          this.scanItemUpdate(eanNum,0);
          }

        } //if

      };
      
      scanItemUpdate(eanNum,isQtyFld) { 
        //alert(eanNum);

        setTimeout(() => {
                   
          const docId = this.props.match.params.id;
          let scanQty =this.state.qtyInput;
          let picklistId =this.state.docItem.picklist_id;
          console.log( picklistId);
          //document.getElementById('item_ean').value=eanNum; 
          
          //alert(eanNum); //.replaceAll(" ","-")
          /*let callUrl = '/api/v1/documents/itemfind/'+docId+'/'+eanNum+'/'+scanQty+'/'+parseInt(isQtyFld);
          fetch(callUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })*/

              const postData = {
                picklistId: picklistId,
                eanNum: eanNum,
                canQty: scanQty,
                isQty: parseInt(isQtyFld),
              };
              //console.log( postData);
              if(this.state.scanned_ean_code === null){
                //this.setState({ upScanQty: true });
                this.setState({ scanned_ean_code: eanNum });
                  fetch('/api/v1/documents/itemfind', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify(postData)
                  })
    
            .then(res => res.json())
            .then(data => {
              //alert(this.state.input);
              this.setState({itemSelected: false}); 
            console.log( data);

                /*if( this.state.itemSelectedPrevQty > 0 ){
                this.setState({itemSelectedPrevQty: this.state.itemSelectedPrevQty-1});
                }
                  
                    if(this.state.itemSelectedPrevId >0 && this.state.itemSelectedPrevQty > 0 ){
                    document.getElementById('itemTap'+this.state.itemSelectedPrevId).setAttribute("style","height: 70px; border: 1px solid green; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");  
                    }*/
                    document.getElementById('item_ean').value=''; 
                    this.setState({ input: "" });
                    this.setState({ scanned_ean_code: null });
                  if(data.status === 'error' ){                  
                    

                    

                    alert("EAN Code not exist!");
                    const focusField = document.querySelector(
                      `input[name=item_ean]`
                    );
                      focusField.focus();
                    this.findDocument(0);
                    //console.log( data.status);
                    //alert('Invalid EAN!');
                    //this.errors = false;
                  }
                  else{
                    //document.getElementById('itemTapQty'+this.state.itemSelectedId).innerHTML =data.itemDet.scanned_qty+"/"+data.itemDet.quantity

                    this.findDocument(0);
                    //this.handleSearch(eanNum);
                   // this.setState({ items: data });
                  }
                  //this.setState({ input: "" });
                 // this.setState({ scanned_ean_code: null });
                  //this.setState({ itemSelected: false });
                  //alert(this.state.input);
                }
                )
                .catch(error => {
                  this.setState({ input: '' });
                  //alert(this.state.input);
                  this.setState({ scanned_ean_code: null });
                  //
                  //alert("EAN Code not exist!");
                  //this.findDocument();
              });  
            }

      

      }, 150);

      }
     
    componentDidMount() {
      if (localStorage.getItem('token') === null) {
        window.location.replace('/cardlogin');
      } 
      //const { studentId } = this.props.match.params;
      //console.log( this.props.match.params);
      this.findDocument(1);
      }

      refreshList = () => {
        this.setState({spinner: true});
        const docId = this.props.match.params.id;
        const currentUser = localStorage.getItem('username');
        const loadStatus = 2 // delt old items and replace all
        fetch('/api/v1/documents/docitems/'+docId+'/'+currentUser+'/'+loadStatus, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          }
        })
          .then(res => res.json())
          .then(data => {
            console.log( data)
            //document.getElementById("item_ean").value='';
            this.setState({itemSelected: false}); 
            const Binrows = [];
            for (let i = 0; i < data.docItemList.length; i++) {
                // note: we are adding a key prop here to allow react to uniquely identify each
                // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
                Binrows.push(data.docItemList[i]);
            }

            this.setState({ spinner: false });
            //alert(data.total_released_item);
            const focusField = document.querySelector(
              `input[name=item_ean]`
            );
              focusField.focus();
          this.setState({ docItem: data.document, items: Binrows,Fixeditems:Binrows,total_released_item:data.total_released_item, total_scanned_item: data.total_scanned_item, AllComments:data.AllComments });
          window.location.reload(); 
          
        }
        )
        .catch(error => {
          this.setState({spinner: false});
          console.log( error);
        });       
          }; 
        
        
     onItemTap = (e,idSeltd,totalQty,scanQty, itemCode, polskiNumber,isBatch,warehouse_code,scan_status) => {     
      if(parseInt(scan_status) === 1){
        this.scanRepeatConfirm(idSeltd);
return false;
      }
      
        /*localStorage.setItem('AbsEntry',this.state.docItem.picklist_id); scan_status
        localStorage.setItem('BaseEntry',this.state.docItem.order_docentry);
        console.log(this.state.docItem.picklist_id);
        console.log(warehouse_code);
        window.location.replace('/stockQtyBinLoc/'+idSeltd+'/'+isBatch+'/'+itemCode+'/delivery');*/
        this.setState({itemSelectedBgColor: scan_status}); 
        //alert(scan_status);
        if(this.state.itemSelectedId >0 && this.state.itemSelectedId !== idSeltd){
          console.log(1);
          if(parseInt(scan_status) === 1 ){
          document.getElementById('itemTap'+this.state.itemSelectedId).removeAttribute("style");  
          document.getElementById('itemTap'+this.state.itemSelectedId).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");
          console.log(2);
        }
        /* else{
          console.log(3);
          document.getElementById('itemTap'+this.state.itemSelectedId).removeAttribute("style");  
          document.getElementById('itemTap'+this.state.itemSelectedId).setAttribute("style", "height: 60px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
            }*/
        document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
        document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
        this.setState({itemSelected: true}); 
      }
        
        else if(this.state.itemSelectedId >0 && this.state.itemSelectedId === idSeltd){

          if(parseInt(scan_status) !== 1 && this.state.itemSelected === true){
            document.getElementById('itemTap'+this.state.itemSelectedId).removeAttribute("style");  
            document.getElementById('itemTap'+this.state.itemSelectedId).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
            this.setState({itemSelected: false}); 

          }
          else if(parseInt(scan_status) !== 1 && this.state.itemSelected === false){
            document.getElementById('itemTap'+this.state.itemSelectedId).removeAttribute("style");  
            document.getElementById('itemTap'+this.state.itemSelectedId).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
            this.setState({itemSelected: true}); 

          }

          //console.log(this.state.itemSelected);
          /*if(this.state.itemSelected === false){
            document.getElementById('itemTap'+this.state.itemSelectedId).removeAttribute("style");  
            document.getElementById('itemTap'+this.state.itemSelectedId).setAttribute("style", "height: 70px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
            this.setState({itemSelected: true}); 
          }
          else{
          document.getElementById('itemTap'+this.state.itemSelectedId).removeAttribute("style");  
          document.getElementById('itemTap'+this.state.itemSelectedId).setAttribute("style", "height: 70px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");
          this.setState({itemSelected: false}); 
          
        }*/
        //return false;
          }
        else{
          //console.log(3);
          document.getElementById('itemTap'+idSeltd).removeAttribute("style");  
          document.getElementById('itemTap'+idSeltd).setAttribute("style", "height: 40px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: blue none repeat scroll 0% 0%;");
          this.setState({itemSelected: true}); 
        }
    

        
        this.setState({itemSelectedId: idSeltd}); 
        this.setState({incnum: totalQty}); 
        this.setState({totalOrderQty: totalQty});

       
      


        
    };  

    scanRepeatConfirm = (itemID) => {
      const docId = this.props.match.params.id;
      const currentUser = localStorage.getItem('username');
      const postData = {
        docId: docId,
        userName: currentUser,
        itemID: itemID,
      };
      if (window.confirm("Are you sure to remove the scanned QTY!")) {
                    fetch('/api/v1/documents/scanRepeatConfirm', {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`
                      },
                      body: JSON.stringify(postData)
                    })
                    .then(res => res.json())
                    .then(data => {
                      console.log( data);
                      //this.findDocument();    
                     window.location.reload();                         
            
                              });     
                  }                
          };  




    incNum =()=>{
      
        this.setState({incnum: this.state.totalOrderQty}); 
        alert("You canot add more QTY than released QTY!");
     
     };
      decNum = () => {
        this.setState({incnum: this.state.totalOrderQty}); 
        alert("You canot add more QTY than released QTY!");
     }
     
     handleChange = (e)=>{
      if(parseInt(this.state.totalOrderQty) === parseInt(e.target.value)){
      this.setState({incnum: e.target.value});      
      }
      else{
        this.setState({incnum: this.state.totalOrderQty}); 
        alert("You canot add more QTY than released QTY!");
             
      }
      
     }
     updateSeltdItemQty = ()=> {
     // alert(this.state.itemSelectedId);
      //alert(this.state.incnum);
      this.setState({ spinner: true });
      if(this.state.incnum >0){
        fetch('/api/v1/documents/ScanItemQtyUpdate/'+this.state.itemSelectedId+'/'+this.state.incnum, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
          }
        })
        .then(res => res.json())
        .then(data => {
          console.log( data.itemDet);
          this.setState({ spinner: false });
          //alert(this.state.itemSelectedBgColor);
          ///document.getElementById('itemTapQty'+this.state.itemSelectedId).
          document.getElementById('itemTapQty'+this.state.itemSelectedId).innerHTML =data.itemDet.scanned_qty+"/"+data.itemDet.quantity
          if(parseInt(this.state.itemSelectedBgColor) === 1){
          document.getElementById('itemTap'+this.state.itemSelectedId).removeAttribute("style");  
          document.getElementById('itemTap'+this.state.itemSelectedId).setAttribute("style", "height: 50px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: green none repeat scroll 0% 0%;");
          }
          else{
            document.getElementById('itemTap'+this.state.itemSelectedId).removeAttribute("style");  
            document.getElementById('itemTap'+this.state.itemSelectedId).setAttribute("style", "height: 50px; border: 1px solid green;color: #fff; margin: 3px; padding: 3px; background: rgb(255, 165, 0) none repeat scroll 0% 0%;");
            }

          this.findDocument(0);    
          this.setState({itemSelected: false});      //itemSelectedBgColor                    
                  
                  });  
        }
      else{
        alert("Please enter the Qty!");
      }
      
    }

  render() {    

    
    if (this.state.sendToSap) return ( <div className="mb-4 scanblock-div"><h1>Sending to SAP,please wait.... </h1></div>);
    
    
    return (

      <div>

              {this.state.hasFullScrn === false && 
                <div className="docinfo ">
                    <div className=" scanblock-div">
                      <h1>{ JSON.parse(sessionStorage.getItem('currentLang'))[13]} {this.state.docItem.picklist_id}</h1>
                      
                    </div>
                   
                        { (this.state.docItem.username === null && this.state.AllComments.length >0) && 
                        <div className="mb-2">
                        <span className="`todo-title mr-2`" >
                          <i className="fa fa-info-circle" aria-hidden="true"></i>
                          &nbsp;{this.state.AllComments} 
                        </span>
                        </div>
                        }  
              </div>
              }

          {this.state.docItem.username ===null &&   
          <div className="mb-4 scanblock-comment" align="center">                
              <button onClick={this.readCommentConfirm}>
              { JSON.parse(sessionStorage.getItem('currentLang'))[16]}
              </button>

          </div> 
          
          }

          {this.state.docItem.username !==null &&
            

            <div  className="scanblock-active">
                 {/* {this.state.hasQty === false && */}
                    <div className="scanblock">                    
                              <div className="mb-2">
                              
                                    <input
                                  name='item_ean'
                                  id='item_ean'
                                  type='text'
                                  placeholder={ JSON.parse(sessionStorage.getItem('currentLang'))[14] +" "+ JSON.parse(sessionStorage.getItem('currentLang'))[17]}
                                  onKeyUp={this.state.handlefindItem}
                                // readOnly={true}
                                  ref={(item_ean) => {item_ean && item_ean.focus() }} 
                                  //value={this.state.scanned_ean_code}
                                  //value={this.state.input}
                                  
                                  //onChange={item_ean => this.onChangeInput(item_ean)}
                                  onChange={item_ean => this.handleSearch(item_ean)}
                                />   
                                
                          </div>
                          
                    </div>
                  {/* }*/}
                  {this.state.upScanQty === true && 
                  
                  <div className="scanblock">
                  <span>Scanning is in progress,please wait...</span>
                          </div>
                          }
          
       
              {/*<div className="scanned-total">
                          <div className="mb-2">
                            <h1> {JSON.parse(sessionStorage.getItem('currentLang'))[25]}   ::  {JSON.parse(sessionStorage.getItem('currentLang'))[26]}   {this.state.items.length} -  {JSON.parse(sessionStorage.getItem('currentLang'))[27]}   {this.state.total_scanned_item.length}</h1>
                        </div>
                          

              </div>*/}
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    //next={this.refreshList}
                    //hasMore={this.state.hasMore}
                    //loader={<h4>.</h4>}
                    height={275}

                  >
                    { this.state.spinner && <div className="mb-2 scanblock-div"><h1>Loading wait.... </h1></div>}
                  

                   {  !this.state.spinner && this.state.items.length <=0 && <div className="mb-4 scanblock-div"><h1>There is no item(s)!
                    </h1></div>      }     
                              {this.state.items.map((i, index) => (

                              <div>      
                                {this.state.showHideEnale === true &&
                                   <div >
                                 {!i.status &&
                                  <div >
                                   <Link to='#' >

                                   <div className={`itemTap`+i.id} id={`itemTap`+i.id} style={i.status ?  scanned_items : scanned_pending} 
                                        >

                                          <div className="items-det">
                                          <span className={`todo-title mr-2`} title={i.item_code}>
                                          {i.item_code}&nbsp;&nbsp;&nbsp;
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                          <span id={`itemTapQty`+i.id}>
                                          {i.scanned_qty}/{i.quantity}
                                          </span></span>
                                          {/*{i.order_docentry} &nbsp;&nbsp;&nbsp;
                                          {i.order_docnum} &nbsp;&nbsp;&nbsp;*/}
                                          <br />
                                          <span style={small_font}>{i.Barcode} </span>
                                           
                                          
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          {/*<span className={`todo-title mr-2`} title={i.deliveryDate}>
                                          {i.deliveryDate}  
                                        </span>*/}

                                        {i.selectedBinData &&
                                          <span style={small_font}>                                     
                                            Bin: {i.selectedBinLocs}                                            
                                           
                                            </span>
                                             }
                                          
                                            </div>
                                           
                                        </div>
                                      </Link> 
                                  </div>
                                }
                                  </div>
                                }
                                {this.state.showHideEnale === false && (
                                

                                      <Link to='#'  onClick={input => this.onItemTap(input,i.id,i.quantity, i.scanned_qty, i.item_code,i.polski_number,String(i.ManageBatchNumbers) === 'Y'? 1:0,i.warehouse_code,i.status ?  1 : 0)}>
                                                                 
                                        <div className={`itemTap`+i.id} id={`itemTap`+i.id} style={i.status ?  scanned_items : scanned_pending}  
                                        >

                                          <div className="items-det">
                                          <span className={`todo-title mr-2`} title={i.item_code}>
                                          {i.item_code}&nbsp;&nbsp;&nbsp;
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                          <span id={`itemTapQty`+i.id}>
                                          {i.scanned_qty}/{i.quantity}
                                          </span></span>
                                          {/*{i.order_docentry} &nbsp;&nbsp;&nbsp;
                                          {i.order_docnum} &nbsp;&nbsp;&nbsp;*/}
                                          {i.Barcode &&
                                         
                                          <span style={small_font}> <br />{i.Barcode} </span>
                                          }
                                           
                                          
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          {/*<span className={`todo-title mr-2`} title={i.deliveryDate}>
                                          {i.deliveryDate}  
                                        </span>*/}

                                        {i.selectedBinData &&
                                          <span style={small_font}>                                  
                                            Bin: {i.selectedBinLocs}                                            
                                           
                                            </span>
                                             }
                                          
                                            </div>
                                            
                                            {/*<div className="scanned-pending-items">                                            
                                            
                                          {i.polski_number &&
                                          <span className="`todo-title mr-2`">                                        
                                            Polski number: {i.polski_number}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            Polski opis: {i.polski_opis}
                                            </span>
                                             }                                          
                                                                                    
                                          {i.selectedBinData &&
                                          <span style={small_font}>   <br/>                                     
                                            Bin: {i.selectedBinLocs}                                            
                                           
                                            </span>
                                             }
                                            </div>*/} 

                                            {/*<div className="scanned-pending-items">                                            
                                            
                                          {i.OpeningRemarks  &&
                                          <span className="`todo-title mr-2`">                                        
                                            Opn Remarks: {i.OpeningRemarks}                                            
                                            </span>
                                             }   
                                             {i.ClosingRemarks  &&
                                          <span className="`todo-title mr-2`">                                       
                                            Cls Remarks: {i.ClosingRemarks}
                                            </span>
                                             }                                        
                                                                                    
                                          {i.Comments &&
                                          <span style={small_font}>   <br/>                                     
                                            Comment: {i.Comments}                                            
                                           
                                            </span>
                                             }  
                                            </div>*/}
                                        </div>
                                       
                                        
                                          
                                          
                                       
                                      
                                      </Link> 
                                      
                                  )}
                                </div>
                              ))}
                  </InfiniteScroll>
                  <br/>
                  {this.state.itemSelected && 
                  <div className={`col-xl-3`}>
              <div className={`input-group`}>
              <div className={`input-group-prepend`}>
                <button className={`btn btn-outline-primary`} type="button" onClick={() =>{this.decNum()}}>-</button>
              </div>
              <input type="text" id="increamentValue" name="increamentValue" className={`form-control`} value={this.state.incnum} onChange={(i) =>{this.handleChange(i)}}/>
              <div className={`input-group-prepend`}>
                <button className={`btn btn-outline-primary`} type="button" onClick={() =>{this.incNum()}}>+</button>
              </div>
              <div className="input-group-prepend">
                <button className="btn btn-outline-primary" type="button" onClick={() =>{this.updateSeltdItemQty()}}>Save QTY</button>
              </div>
            </div>
              
              </div>}
        <br />
                  <div className="mb-12 text-center jumbotron-icon panel_footer">
                  
                  <Link to='/pickandpack' >
                    <button >
                    <i className="fa fa-arrow-left fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[54]}  
                    </button>
                    </Link>
                    &nbsp;
                    <button onClick={this.refreshList}>
                    
                    <i className="fa fa-refresh fa-2" aria-hidden="true"></i>
                    <br />Refresh
                    </button>
                    &nbsp;   
                    <button onClick={() =>{this.tapToHideShow()}} >
                    <i className="fa fa-bolt fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[29]}  
                    </button>
                    &nbsp;                
                    <button onClick={() =>{this.sendToSap()}} >
                    <i className="fa fa-check fa-2" aria-hidden="true"></i>
                    <br />
                    {JSON.parse(sessionStorage.getItem('currentLang'))[28]}                    
                    </button>
                </div>
            </div>

          }
        
      </div>
      
    );

  }

}


export default Docdetails;